import React from 'react';
import Typed from 'typed.js';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function EntryHeader({
     desc, desc2, buttonLabel , emphasized, email, desc3, img , size, emailLink
}) {

const element = React.useRef(null);

React.useEffect(() => {
    const typed = new Typed(element.current, {
        strings: ['BUSINESS', 'TECHNOLOGY', 'CYBER SECURITY', 'USER EXPERIENCE', 'BUSINESS + TECHNOLOGY'],
        typeSpeed: 70,
        showCursor: false
    });
    return () => {
        typed.destroy();
    };
}, []);


  return (
    <>
    <div id="EntryHeader" className='home__entry-header-section'>
        <div className={size == "-mob" ? `container${size}` : "container"}>
            <div className='home__entry-header-text-wrapper'>
                <div className={size == " " ? 'desc' : `desc desc${size}`}>
                    {desc}
                    <strong className={size == "-mob" ? `emphasized${size}` : 'emphasized'}>
                        {emphasized}
                    </strong>,<br/> 
                    {desc2} <br/>
                    {desc3}
                    <span ref={element}></span>
                    <span className='blink'>
                        <img src={img} className="entry-header-img"/>
                    </span>
                </div>
                <div className={size == "-mob" ? `home__entry-header-button${size}` : `home__entry-header-button`}>
                    <Link to={emailLink} className={`entry-header-a${size}`}>
                        <span className='entry-header-spacing'>
                            <Button buttonSize='btn--mob' buttonColor='secondary' buttonStyle='btn--primary'> {'Email Me'}</Button>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default EntryHeader;