import React, { useState, useEffect} from 'react';

function AboutBody({
    imgStart, desc, desc2, desc3, img, imgWide, alt, size }){

    const [shouldAnimate, setShouldAnimate] = useState(false);

    const handleScroll = () => {
    const textElement = document.querySelector('.fade-container');
        if (textElement) {
            const rect = textElement.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight - 300;
            console.log(shouldAnimate);
            setShouldAnimate(isVisible);
             }
        };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <>
        <div className='about__body-section'>
            <div className={`container${size}`}>
                <div id={size == " " ? (imgStart === "reverse" ? "row-reverse" : "row" ) : "col"} className={`about__body${size}`}>
                    <div className={`text-body${size}`}>
                        <div 
                        className={`about__body-text-wrapper${size}`}>
                            <div className={`about-desc${size}`}>
                                <p className={`fade-container ${shouldAnimate ? 'animate' : ''}`}>{desc}</p> <br/>
                                <p className={`fade-container ${shouldAnimate ? 'animate' : ''}`}>{desc2}</p> <br/>
                                <p className={`fade-container ${shouldAnimate ? 'animate' : ''}`}>{desc3}</p> <br/>
                            </div>
                        </div>
                    </div>
                    <div className={`img-body${size}`}>
                        <div 
                        className={`about__body-img-wrapper${size} ` + `fade-container ${shouldAnimate ? 'animate' : ''}`}>
                            <img src={(size == " " ? `${img}` : `${imgWide}`)} alt={alt} className={`about__body-img${size}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutBody;