import React from 'react';
import { useState, useEffect } from 'react';
import EntryHeader from '../../EntryHeader';
import Navbar from '../../navbar';
import Header from '../../Header';
import AboutBody from '../../AboutBody';
import { footerListData, skillsListData, skillsData, projectData, aboutPersonData , aboutProfessData, entryHeaderData, aboutData, projectListData} from './data';
import Skills from '../../Skills';
import Footer from '../../Footer';
import ProjectBody from '../../ProjectBody';
import { Link, animateScroll as scroll } from "react-scroll";


function Home() {

  //== Create useState & useEffect function to handle resizing ==
  //=============================================================

    const [isDesktop, setIsDesktop] = useState(true);
    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [size, setSize] = useState(' ');
  
    useEffect(() => {
      function handleResize() {
        if(window.innerWidth >920){
          setIsDesktop(true);
          setIsTablet(false);
          setIsMobile(false);
        } else if (window.innerWidth <= 920 && window.innerWidth >= 620){
          setIsDesktop(false);
          setIsTablet(true);
          setIsMobile(false);
        } else if (window.innerWidth < 620){
          setIsDesktop(false);
          setIsTablet(false);
          setIsMobile(true);
        }
      }
      window.addEventListener('resize', handleResize);
      handleResize();
    }, []);
  
    useEffect(() => {
      if (isDesktop === true) {
        setSize(" ");
      } else if (isTablet === true) {
        setSize('-tab');
      } else if (isMobile === true) {
        setSize('-mob');
      }
    }, [[isDesktop, isTablet, isMobile]]);
  

  let sizeDesc = size;
  console.log('Current size:', sizeDesc);


  //== Active List of used Components being displayed ==
  //====================================================

  return (
    <>
        <Navbar size={size}/>
        <EntryHeader id="EntryHeader" {...entryHeaderData} size={size}/>
        <Header {...aboutData} size={size}/>
        <AboutBody {...aboutProfessData} size={size}/>
        <AboutBody {...aboutPersonData} size={size}/>
        <Header {...skillsData} size={size}/>
        <Skills {...skillsListData} size={size}/>
        <Header {...projectData} size={size}/>
        <ProjectBody {...projectListData} size={size}/>
        <Footer {...footerListData} size={size}/>
    </>)}

export default Home;