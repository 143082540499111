import React from 'react'

const STYLE = ['btn--primary', 'btn--outline']

const SIZE = ['btn--med', 'btn--lg', 'btn--mob','btn--wide']

const COLOR = ['primary', 'secondary']

export const Button = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    buttonColor
}) => {

    const checkButtonStyle = STYLE.includes(buttonStyle) ? buttonStyle : STYLE[0];
    const checkButtonSize = SIZE.includes(buttonSize) ? buttonSize : SIZE[0];
    const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : COLOR[1];

    return (
        <button className={`btn  ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`} onClick={onClick} type={type}>{children}</button>
    )
}