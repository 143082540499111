//== ENTRY HEADER ==
//==================

export const entryHeaderData = {
    desc: "HEY! I'M ",
    desc2: 'A FULL STACK DEVELOPER WITH A PASSION',
    desc3: ' FOR ',
    emphasized: 'BEN HINES',
    email: 'benjaminisaachines@gmail.com',
    emailLink: "mailto:benjaminisaachines@gmail.com?Subject=Connection%20from%20your%20Website",
    img: "images/rectangle_cursor.png"
}


// BUSINESS + TECHNOLOGY

//== ABOUT SECTION ==
//===================

export const aboutProfessData = {
    imgStart: '', 
    desc: "As a results-driven individual passionate about combining business with technology, I co-founded a start-up called Fanvester early in my career, gaining first-hand experience in the crucial role of technology in business success. This inspired me to pursue a career in software engineering.",
    desc2: "After obtaining a degree in Interactive Media Studies and Interpersonal Communication from Miami University, I pursued further education in software programming, recently graduating from General Assembly's Software Engineering Program. I'm committed to mental health advocacy and helping those who struggle with mental health access necessary resources.",
    desc3: "My mission is to leverage my expertise in technology and business to foster innovation, creativity, and growth in supportive and inclusive environments. I'm committed to nurturing a culture of learning and mentorship to help individuals achieve their full potential. Ultimately, I aim to be a driving force in the tech industry, promoting diversity, inclusion, and advancement through my work and leadership. If you'd like to learn more or discuss potential collaborations, let's connect!",
    img: 'images/headshotBW.jpg', 
    imgWide: 'images/headshot-wide.png',
    alt: ''
}

export const aboutPersonData = {
    imgStart: 'reverse', 
    desc: "Born and raised in Cincinnati, I now live in Norristown, PA with my wife, Meredith. When not working, I enjoy playing video games like Super Smash, Mario Kart, and Call of Duty Warzone 2, and pursuing outdoor hobbies like gardening, football, basketball, and tennis.",
    desc2: "As an advocate for the planet, I believe gardening offers therapeutic benefits and positively impacts our planet. I'm also committed to mental health advocacy and being an ally for those who struggle with mental health.",
    desc3: "Ultimately, I cherish life's simple pleasures like sipping coffee with loved ones and spending quality time together. Please feel free to connect with me by clicking one of the social links listed below, clicking on the contact me button, or adding me on Xbox.",
    img: 'images/usBW_Port.jpg', 
    imgWide: 'images/us_landscape_bw.png',
    alt: ''
}


//== HEADER SECTION ==
//====================

export const aboutData = {
    lightBg: true,
    lightText: false,
    headerName: "ABOUT",
    idName: "about",
}

export const skillsData = {
    lightBg: false,
    lightText: true,
    headerName: "SKILLS",
    idName: "skills",
}

export const projectData = {
    lightBg: true,
    lightText: false,
    headerName: "PROJECTS",
    idName: "projects",
}


//== SKILLS SECTION ==
//====================

export const skillsListData = [
    {
        skillHeader: "Languages",
        skillList: ["HTML", "CSS", "JavaScript", "Java", "Python", "SQL", "PHP"],
    },
    {
        skillHeader: "Frameworks",
        skillList: [ "Angular", "SpringBoot", "Spring", "SpringMVC", "Django", "Boostrap", "jQuery", "ExpressJS"],
    },
    {
        skillHeader: "Libraries",
        skillList: ["React", "Bcrypt", "Multiple.js", "Mongoose", "Cors", "Dotenv"]
    },
    {
        skillHeader: "Tools",
        skillList: ["NodeJS","Apigee","Dynatrace", "SonarQube", "MongoDB", "Git", "Maven"]
    }
]


//== PROJECT SECTION ==
//=====================

export const projectListData = [
    {
        blueBg: false,
        projectHeader: "Pokedex",
        projectWebURL: "http://pokedex.benjaminisaachines.com/pokedex",
        linkWebAlt: "Click here to visit Pokedex in action",
        projectGitURL: "https://github.com/h1n3s1ght/pokedex",
        linkGitAlt: "Click here to visit Pokedex on GitHub",
        projectDescription: "Developed to showcase all 1st Gen Pokemon and their stats",
        projectImg: "images/PokedexDisplay.png",
        alt: " ",
        inProgress: false,
        projectFeatures: ["EJS", "JavaScript" , "CSS"]
    },
    {
        blueBg: true,
        projectHeader: "What-To-Eat",
        projectWebURL: "/",
        linkWebAlt: "Click here to visit What To Eat in action",
        projectGitURL: "/",
        linkGitAlt: "Click here to visit What To Eat on GitHub",
        projectDescription: "Utilizes an API accessing 1.7M recipes to help users determine a new random recipe that fits your nutrition expectations",
        projectImg: "images/What-To-Eat.png",
        alt: " ",
        inProgress: true,
        projectFeatures: ["Python", "Django", "Angular", "PostgreSQL"]
    },
    {
        blueBg: false,
        projectHeader: "More to come...",
        projectWebURL: "/",
        projectGitURL: "/",
        projectDescription: "I have several projects in the ideation phase, and I am currently in the process of finding time to work on each of them. However, due to my commitments to work and self-directed education, I haven't been able to release any new projects yet. If you have any ideas or suggestions related to these projects, I would greatly appreciate it if you could reach out to me using the Email or Connect Links above.",
        projectImg: "images/EmptyComp.png",
        alt: " ",
        inProgress: true,
        projectFeatures: []
    }
    // {
    //     blueBg: false,
    //     projectHeader: "Organyze",
    //     projectWebURL: "/",
    //     linkWebAlt: "Click here to visit Organyze in action",
    //     projectGitURL: "/",
    //     linkGitAlt: "Click here to visit Organyze on GitHub",
    //     projectDescription: "Designed to help maintain an organyze digital list of tasks that I plan to have accessible by Smart Home Devices like (Siri, Alexa, Google, etc)",
    //     projectImg: "images/EmptyComp.png",
    //     alt: " ",
    //     inProgress: true,
    //     projectFeatures: ["React", "Node" , "Express"]
    // }
]




//== FOOTER SECTION ==
//====================

export const footerListData = [
    {
        itemName: "Email",
        className: "email",
        itemLink: "mailto:benjaminisaachines@gmail.com?Subject=Connection%20from%20your%20Website",
        itemLogoLink: "images/email.png",
        isDownload: false,
    },
    {
        itemName: "Instagram",
        className: 'instagram', 
        itemLink: "https://www.instagram.com/hinesight_/", 
        itemLogoLink: "images/instagram.png",
        isDownload: false,
    },
    {
        itemName: "LinkedIn", 
        className: "linkedin",
        itemLink: "https://www.linkedin.com/in/benjaminisaachines/", 
        itemLogoLink: "images/linkedin.png",
        isDownload: false,
    },
    {
        itemName: "GitHub", 
        className: "github",
        itemLink: "https://github.com/h1n3s1ght", 
        itemLogoLink: "images/github.png",
        isDownload: false,
    },
    {
        itemName: "Resumé",
        className: "resume",
        itemLink: "files/BenHines_Resume(Feb2023).pdf",
        itemLogoLink: "images/resume.png",
        isDownload: true,
    }
]