import React, { useEffect, useState } from 'react'
import { skillsListData } from './pages/homepage/data'
import './Skills.css'

function Skills({
    skillList, skillHeader , size
}) {

    const [shouldAnimate, setShouldAnimate] = useState(false);

    const handleScroll = () => {
    const textElement = document.querySelector('.fade-skills-container');
        if (textElement) {
            const rect = textElement.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight - 250;
            console.log(shouldAnimate);
            setShouldAnimate(isVisible);
             }
        };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <>
        <div className={`skills__body-section${size}`}>
            <div className={`container${size}`}>
                <div className={`skills__col-items${size}`}>
                    <div className={`skills__body-text-wrapper${size}`}>
                        <div className={`skills-list${size}`}>
                            {skillsListData.map(item => (
                                <div key={item.skillHeader} className={`skills__ul-lists${size}`}>
                                <h3 className={`skillsHeader${size}`}>{item.skillHeader}</h3>
                                <ul className={`skills-unordered${size} ` + `fade-skills-container ${shouldAnimate ? 'animate' : ''}`}>
                                {item.skillList.map((itemSm, indexSm) => (
                                    <li key={`${item.skillHeader}-${indexSm}`}>{itemSm}</li>
                                ))}
                                </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Skills