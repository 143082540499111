import React from 'react'

function Header({
    headerName, lightBg, lightText , size , idName
}) {
  return (
    <>
        <div id={idName} className={lightBg ? 'header-section' : 'header-section darkBg'}>
            <div className={`text-wrapper${size}`}>
                    {headerName}
            </div>
        </div>
    </>
  )
}

export default Header