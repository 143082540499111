import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
// import { HiOutlineMail } from "react-icons/hi";
import { RiPagesLine } from "react-icons/ri";
import { ImHome3 } from "react-icons/im";
import { Button } from "./Button";
import "./navbar.css";
import { Link } from "react-scroll";

function Navbar({
  size
}) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobMenu = () => setClick(false);

  const [button, setButton] = useState(true);

  const showButton = () => {
    if (size !== " ") {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <div className="navbar">
        <div className="navbar-container container">
          <Link 
          to="EntryHeader" 
          smooth={true} 
          duration={500}
          className="navbar-logo" 
          onClick={closeMobMenu}>
            Ben Hines
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            {click ? (
              <FaTimes style={{ color: "#2F4858" }}/>
            ) : (
              <FaBars style={{ color: "#2F4858" }}/>
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link 
              to="about" 
              smooth={true}
              duration={500}
              offset={-80}
              className="nav-link"
              onClick={closeMobMenu}>
                {click ? <ImHome3 className="nav-link-svg" /> : null} About
              </Link>
            </li>
            <li className="nav-item">
              <Link 
              to="skills" 
              smooth={true}
              duration={500}
              offset={-80}
              className="nav-link"
              onClick={closeMobMenu}>
                {click ? <IoMdPerson className="nav-link-svg" /> : null} Skills
              </Link>
            </li>
            <li className="nav-item">
              <Link 
              to="projects" 
              smooth={true}
              duration={500}
              offset={-80}
              className="nav-link"
              onClick={closeMobMenu}>
                {click ? <RiPagesLine className="nav-link-svg" /> : null}
                Projects
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link 
              to="contact" 
              className="nav-link">
                {click ? <HiOutlineMail className="nav-link-svg" /> : null}
                Contact
              </Link>
            </li> */}
            <li>
              {Button ? 
                ( <a href="files/BenHines_Resume(Feb2023).pdf" download={true} target="_blank" className="btn-link">
                  <Button buttonStyle='btn--outline'>
                   Resume
                  </Button>
                </a>) 
               :  
                (<a href="files/BenHines_Resume(Feb2023).pdf" download={true} target="_blank" className="btn-link">
                  <Button buttonStyle="btn--outline" buttonSize="btn--mobile">
                    Resume
                  </Button>
                </a>) 
              }
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
