import React from 'react'
import { projectListData } from './pages/homepage/data';
import { FiExternalLink } from 'react-icons/fi'
import {VscGithub} from 'react-icons/vsc';

function ProjectBody({size}) {
  return (
    <>
    {projectListData.map(item => (
        <div key={item.projectHeader} className={item.blueBg ? `project-page${size} blueBg` : `project-page${size}`}>
          <div className={`container${size}`}>
            <div className={`project-text-wrapper${size}`}>
              <div className={`project${size}`}>
                <h2 className={`project-header${size}`}>{item.projectHeader}</h2>
                <p className={`project-desc${size}`}>
                  {item.inProgress ? (<span style={{ fontWeight: 'bold'}}><br/>| IN PROGRESS | <br/><br/></span>) : null}
                  {item.projectDescription}</p>
                <ul className={`project-feat-ul${size}`}>
                  {item.projectFeatures.map((itemFeat, indexFeat) => (
                    <li key={`${item.projectHeader}-${indexFeat}`} className={`project-feat-li${size}`}>{itemFeat}</li>
                      ))}
                </ul>
                <ul className={`project-link-ul${size}`}>
                  <li className={`project-link-li${size}`}>
                    <a href={item.projectGitURL} alt={item.linkGitAlt} title={item.linkGitAlt}target="_blank">
                      {<VscGithub className={`project-git${size}`}/>}
                    </a>
                  </li>
                  <li className={`project-link-li${size}`}>
                    <a href={item.projectWebURL} alt={item.linkWebAlt} title={item.linkWebAlt} target="_blank">
                      {<FiExternalLink className={`project-web${size}`}/>}
                    </a>
                  </li>
                </ul>
              </div>
              <div key={item.projectImg} className={`project-img-container${size}`}>
                <img src={item.projectImg} alt={item.alt} className={`project-img${size}`}/>
              </div>
            </div>
          </div>
        </div>
      ))}
      <span className={`blue-span${size}`}></span>
    </>
  )
}

export default ProjectBody;