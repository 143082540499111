import React from 'react'
import './Footer.css'
import { footerListData } from './pages/homepage/data';

function Footer({
    itemName, itemLink, itemLogoLink, className, isDownload
}) {
  return (
    <>
        <div className='footer__body-section'>
            <div className='container col'>
                <div className='footer__items'>
                    <div className='footer__body-wrapper'>
                        <div className='footer-list'>
                            <ul className='social-links-ul'>
                                <div className='footer-button'> 
                                    {footerListData.map((item, index) => (
                                        <li className='social-link' key={index}>
                                            <a href={item.itemLink} className={`social-a ${item.className}`} download={item.isDownload ? 'download' : null} target="_blank">
                                                <span className='img-span'></span>
                                                <span className='img-span'></span>
                                                <span className='img-span'></span>
                                                <span className='img-span'></span>
                                                <img src={item.itemLogoLink} className='logo-img'/>
                                            </a>
                                        </li>
                                    ))}
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className='copyright-wrapper'>
                        <p className='copyright'>
                            Ⓒ Copyright 2022 by Ben Hines. All rights reserved.
                         </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer;